import { FilterManager, callbackManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { TRAVELLER_CONTANT_INFO } from '../URL'

const TravellerContactInfo = {}
const URL = TRAVELLER_CONTANT_INFO

TravellerContactInfo.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default TravellerContactInfo