import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { USER } from '../URL'

const Service = {}
const URL = USER

Service.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page?.customOffset ?? page.offset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page?.pageSize ?? page.limit ?? 10)
    


    FilterManager(data, page?.filters)
    


    return fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.edit = async function (user, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('name', user.name)
    data.append('surname', user.surname)
    data.append('email', user.email)
    data.append('tel', user.Tel)
    data.append('TcKimlikNo', user.TcKimlikNumarasi)
    data.append('OrganizationId', user.OrganizationId)
    data.append('BagliOlduguKisiId', user.BagliOlduguKisiId ? user.BagliOlduguKisiId : '0')


    fetch({
        url: URL.EDIT + user.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.add = function (user, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('name', user.name)
    data.append('surname', user.surname)
    data.append('email', user.email)
    data.append('tel', user.Tel)
    data.append('TcKimlikNo', user.TcKimlikNumarasi)
    data.append('GecikmesindeSakincaOlanHal', user.GecikmesindeSakincaOlanHal ? '1' : '0')
    data.append('BagliOlduguKisiId', user.BagliOlduguKisiId ? user.BagliOlduguKisiId : '0')

    if (user?.OrganizationId) {
        data.append('OrganizationId', user?.OrganizationId)
    }

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Service.addRole = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    try {
        for await (const id of item.RoleId) {
            const data = new FormData()
            data.append('UserId', item.UserId)
            data.append('RoleId', id)

            await fetch({
                url: "/Api/RoleObjModel",
                method: 'post',
                data: data
            })
        }
        callbackManager(null, setData, loading, cb)
    } catch (error) {
        if (loading) loading(false)
    }

}

Service.addGroup = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    try {
        for await (const id of item.UserGroupId) {
            const data = new FormData()
            data.append('UserId', item.UserId)
            data.append('UserGroupId', id)

            await fetch({
                url: "/Api/RoleObjModel",
                method: 'post',
                data: data
            })
        }
        callbackManager(null, setData, loading, cb)
    } catch (error) {
        if (loading) loading(false)
    }

}

Service.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message)
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}


Service.logout = function () {
    return fetch({
        url: '/Authorazation/Logout',
        method: 'POST'
    })
}

export default Service