import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { TRANSFER_DOMESTIC } from '../URL'

const TransferDomestic = {}
const URL = TRANSFER_DOMESTIC

TransferDomestic.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TransferDomestic.get_liman = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    // data.append('arrival_airport', page.arrival_airport ?? '')
    // data.append('departure_airport', page.departure_airport ?? '')
    // data.append('UcusNumarasi', page.UcusNumarasi ?? '')
    // data.append('ad', page.ad ?? '')
    // data.append('soyad', page.soyad ?? '') 
    // data.append('kalkisUlkeId', page.kalkisUlkeId ?? '')
    // data.append('varisUlkeId', page.varisUlkeId ?? '')
    FilterManager(data, page.filters)

    fetch({
        url: URL.GET_LIMAN,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}



export default TransferDomestic