import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { ANNOUNCEMENT }  from '../URL'

const Announcement = {}
const URL = ANNOUNCEMENT

Announcement.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)

    FilterManager(data, page?.filters)

   
  
    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Announcement.edit = async function (role, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('Title', role.Title)
    data.append('Explanation', role.Explanation)
    data.append('Date', role.Date[0].format('YYYY-MM-DD'))
    data.append('EndDate', role.Date[1].format('YYYY-MM-DD'))
    data.append('SendAnnouncementUserGroups', role.SendAnnouncementUserGroups)
    data.append('IsRequiredToShown', role.IsRequiredToShown ? '1' : '0')
    data.append('IsSendMail', role.IsSendMail ? '1' : '0')

    fetch({
        url: URL.EDIT + role.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Announcement.add = function (role, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('Title', role.Title)
    data.append('Explanation', role.Explanation)
    data.append('Date', new Date(role.Date).toLocaleDateString('tr-TR'))
    data.append('EndDate', new Date(role.EndDate).toLocaleDateString('tr-TR'))
    data.append('IsRequiredToShown', role.IsRequiredToShown ? '1' : '0')
    data.append('IsSendMail', role.IsSendMail ? '1' : '0')

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Announcement.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default Announcement