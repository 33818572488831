
const dev = {
	API_ENDPOINT_URL: 'https://yolcutakip-api.solvera.com.tr/',
	TILE_SERVER_URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	QUERY_ENGINE_V2_URL: 'https://yolcutakip-query-api.solvera.com.tr'
};

const prod = {
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
	TILE_SERVER_URL: process.env.REACT_APP_TILE_SERVER_URL,
	QUERY_ENGINE_V2_URL: process.env.REACT_APP_QUERY_ENGINE_V2_URL
};

const test = {
	API_ENDPOINT_URL: 'https://yolcutakipapi.solvera.com.tr',
	TILE_SERVER_URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	QUERY_ENGINE_V2_URL: 'https://yolcutakip-query-api.solvera.com.tr'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
